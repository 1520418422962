<template>
  <div>
    <div class="ml-5 mr-10">
      <h2 style="color: black">{{ $t("socialhistory") }}</h2>
      <div class="w-full ml-5 mr-5">
        <!-- birth  -->
        <div div class="vx-row mt-5">
          <b class="xl:w-3/12 text-left m-2 w-full">{{ $t("BirthDay") }}</b>
          <datepicker
            :placeholder="$t('BirthDay')"
            v-model="patientModel.BirthDate"
            class="vx-col lg:w-3/12"
          ></datepicker>
          <!-- gender  -->
          <b class="vx-col xl:w-1/12 m-2 w-full">{{ $t("Gender") }}</b>
          <v-select
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            label="Name"
            class="vx-col xl:w-4/12"
            v-model="patientModel.GenderID"
            :options="genders"
            :reduce="(ID) => ID.ID"
          />
        </div>

        <!-- MaritalStatus -->
        <div div class="vx-row mt-5">
          <b class="xl:w-3/12 text-left m-2 mr-6 w-full">{{ $t("MaritalStatus") }}</b>

          <vs-radio
            class="lg:w-1/7"
            v-model="patientModel.MaritalStatusID"
            :vs-value="1"
            @input="patientModel.HaveChildren=false"
            >{{ $t("Single") }}</vs-radio
          >
          <vs-radio
            class="lg:w-1/7"
            v-model="patientModel.MaritalStatusID"
            :vs-value="4"
            >{{ $t("Married") }}</vs-radio
          >
          <vs-radio
            class="lg:w-1/7"
            v-model="patientModel.MaritalStatusID"
            :vs-value="2"
            >{{ $t("Divorced") }}</vs-radio
          >
          <vs-radio
            class="lg:w-1/7"
            v-model="patientModel.MaritalStatusID"
            :vs-value="3"
            >{{ $t("Widowed") }}</vs-radio
          >
        </div>

        <!-- HaveChildren -->
        <div class="vx-row mt-5" v-if="patientModel.MaritalStatusID !=1">
          <b class="xl:w-3/12 text-left m-2">{{ $t("HaveChildren") }}</b>
          <vs-radio
            class="vx-col lg:w-1/7"
            v-model="patientModel.HaveChildren"
            :disabled="patientModel.MaritalStatusID == 1"
            vs-name="HaveChildren"
            :vs-value="true"
            >{{ $t("yes") }}</vs-radio
          >
          <vs-radio
            class="vx-col lg:w-1/7"
            v-model="patientModel.HaveChildren"
            :disabled="patientModel.MaritalStatusID == 1"
            vs-name="HaveChildren"
            :vs-value="false"
            >{{ $t("no") }}</vs-radio
          >

          <p class="vx-col lg:w-1/7">{{ $t("Ifyeshowmany") }}:</p>

          <vs-input
            class="vx-col lg:w-1/8"
            :disabled="!patientModel.HaveChildren || patientModel.MaritalStatusID == 1"
            v-model="patientModel.NumOfChildren"
            :placeholder="$t('NumOfChildren')"
          />

          <p class="vx-col lg:w-1/8 w-full">{{ $t("Age") }}:</p>
          <vs-input
            class="vx-col lg:w-1/8"
            v-model="patientModel.AgeOfChildren"
            :disabled="!patientModel.HaveChildren || patientModel.MaritalStatusID == 1"
            :placeholder="$t('5,8,6,..')"
          />
        </div>

        <!-- Internationalinsurance -->
        <div class="vx-row mt-5">
          <b class="xl:w-3/12 text-left m-2"> {{ $t("Internationalinsurance") }}</b>

          <vs-radio
            class="vx-col lg:w-1/7"
            v-model="patientModel.PatientMedicalHistory.IsHaveInternationalInsurance"
            vs-name="Internationalinsurance"
            :vs-value="true"
            >{{ $t("yes") }}</vs-radio
          >
          <vs-radio
            @change="
              patientModel.PatientMedicalHistory.InsuranceCompanyID = null;
              patientModel.PatientMedicalHistory.InsuranceCompanyName = null;
            "
            class="vx-col lg:w-1/7"
            v-model="patientModel.PatientMedicalHistory.IsHaveInternationalInsurance"
            vs-name="Internationalinsurance  "
            :vs-value="false"
            >{{ $t("no") }}</vs-radio
          >

          <p class="vx-col lg:w-1/7">{{ $t("Insurancecompanyname") }}</p>
          <vs-select
            :disabled="!patientModel.PatientMedicalHistory.IsHaveInternationalInsurance"
            class="vx-col lg:w-1/7"
            v-model="patientModel.PatientMedicalHistory.InsuranceCompanyID"
          >
            <vs-select-item
              :value="null"
              text="Other Company"
              style="background-color: #352566"
            />
            <vs-select-item
              :key="index"
              :value="item.ID"
              :text="item.Name"
              v-for="(item, index) in companies"
            />
          </vs-select>

          <vs-input
            v-if="
              patientModel.PatientMedicalHistory.InsuranceCompanyID == null &&
              patientModel.PatientMedicalHistory.IsHaveInternationalInsurance
            "
            class="vx-col lg:w-1/7"
            vs-name="Insurancecompanyname"
            v-model="patientModel.PatientMedicalHistory.InsuranceCompanyName"
            :placeholder="$t('companyname')"
          />
        </div>

        <!-- Areyouasmoker -->
        <div div class="vx-row mt-5">
          <b class="xl:w-3/12 text-left m-2">
            <p>{{ $t("Areyouasmoker") }}</p></b
          >
          <vs-radio
            class="vx-col lg:w-1/7"
            v-model="patientModel.PatientMedicalHistory.IsSmoker"
            :vs-value="true"
            vs-name="Areyouasmoker"
            >{{ $t("yes") }}</vs-radio
          >
          <vs-radio
            class="vx-col lg:w-1/7"
            v-model="patientModel.PatientMedicalHistory.IsSmoker"
            vs-name="Areyouasmoker"
            :vs-value="false"
            >{{ $t("no") }}</vs-radio
          >
          <div class="vx-row ml-4">
            <p>{{ $t("Level") }}:</p>
            <div class="vx-col">
              <vs-radio
                class="m-1"
                :disabled="!patientModel.PatientMedicalHistory.IsSmoker"
                vs-name="SmokeDegreeID"
                v-model="patientModel.PatientMedicalHistory.SmokeDegreeID"
                :vs-value="1"
                >{{ $t("Heavy") }}</vs-radio
              >
              <vs-radio
                class="m-1"
                :disabled="!patientModel.PatientMedicalHistory.IsSmoker"
                vs-name="SmokeDegreeID"
                v-model="patientModel.PatientMedicalHistory.SmokeDegreeID"
                :vs-value="2"
                >{{ $t("Moderate") }}</vs-radio
              >
              <vs-radio
                class="m-1"
                :disabled="!patientModel.PatientMedicalHistory.IsSmoker"
                vs-name="SmokeDegreeID"
                v-model="patientModel.PatientMedicalHistory.SmokeDegreeID"
                :vs-value="3"
                >{{ $t("Occasionaly") }}</vs-radio
              >
            </div>
          </div>
        </div>

        <!-- section Clarificationofeachcriteria6cigarettesadayconsideredasmoderate -->
        <h4 class="mt-5">
          <p>
            {{ $t("Clarificationofeachcriteria6cigarettesadayconsideredasmoderate") }}
          </p>
        </h4>

        <!-- Doyoudrinkalcohol -->
        <div class="vx-row mt-5">
          <b class="xl:w-3/12 text-left m-2 w-full">
            <p>{{ $t("Doyoudrinkalcohol") }}</p></b
          >
          <vs-radio
            class="vx-col lg:w-1/5"
            vs-name="IsDrinkAlcoholCurrently"
            @change="
              patientModel.PatientMedicalHistory.NoIquit = false;
              patientModel.PatientMedicalHistory.IsDrinkAlcoholRarely = false;
            "
            v-model="patientModel.PatientMedicalHistory.IsDrinkAlcoholCurrently"
            :vs-value="true"
            >{{ $t("Yescurrently") }}</vs-radio
          >
          <vs-radio
            vs-name="IsDrinkAlcoholCurrently"
            class="vx-col lg:w-1/5"
            v-model="patientModel.PatientMedicalHistory.IsDrinkAlcoholRarely"
            @change="
              patientModel.PatientMedicalHistory.NoIquit = false;
              patientModel.PatientMedicalHistory.IsDrinkAlcoholCurrently = false;
            "
            :vs-value="true"
            >{{ $t("Yesbutoccasionallyrarely") }}</vs-radio
          >
          <vs-radio
            class="vx-col lg:w-1/5 w-full"
            vs-name="IsDrinkAlcoholCurrently"
            @change="
              patientModel.PatientMedicalHistory.IsDrinkAlcoholRarely = false;
              patientModel.PatientMedicalHistory.IsDrinkAlcoholCurrently = false;
            "
            v-model="patientModel.PatientMedicalHistory.NoIquit"
            :vs-value="true"
            >{{ $t("no") }}</vs-radio
          >
        </div>

        <!-- Howmanydrinksperday -->
        <div class="vx-row mt-5">
          <p class="xl:w-3/12 text-left m-2">{{ $t("Howmanydrinksperday") }}:</p>

          <vs-input
            :disabled="patientModel.PatientMedicalHistory.NoIquit"
            v-model="patientModel.PatientMedicalHistory.ManyDrinksAday"
            class="flex-1"
            :placeholder="$t('drinksperday')"
            vs-name="IsDrinkAlcoholCurrently"
          ></vs-input>
        </div>

        <!-- Areyoudiagnosedwithloworhighpressure -->
        <div class="vx-row mt-5">
          <b class="xl:w-3/12 text-left m-2">
            <p>{{ $t("Areyoudiagnosedwithloworhighpressure") }}</p>
          </b>
          <vs-radio
            class="lg:w-1/7"
            v-model="patientModel.PatientMedicalHistory.IsHavePresssure"
            :vs-value="true"
            vs-name="IsHavePresssure"
            >{{ $t("Highpressure") }}</vs-radio
          >
          <div class="lg:w-1/7"></div>
          <vs-radio
            class="lg:w-1/5"
            v-model="patientModel.PatientMedicalHistory.IsLowPressure"
            :vs-value="true"
            vs-name="IsHavePresssure"
            >{{ $t("Lowpressure") }}</vs-radio
          >

          <vs-radio
            class="vx-col lg:w-1/5"
            v-model="patientModel.PatientMedicalHistory.IsHavePresssure"
            :vs-value="false"
            vs-name="IsHavePresssure"
            >{{ $t("no") }}</vs-radio
          >
        </div>

        <!-- Areyouasthmatic -->
        <div class="vx-row mt-5 w-full">
          <b class="xl:w-3/12 sm:w-1/4 text-left m-2">
            <p>{{ $t("Areyouasthmatic") }}</p>
          </b>
          <vs-radio
            class="vx-col lg:w-1/7"
            vs-name="Areyouasthmatic"
            v-model="patientModel.PatientMedicalHistory.IsHaveAsthmatic"
            :vs-value="true"
            >{{ $t("yes") }}</vs-radio
          >
          <vs-radio
            @change="patientModel.PatientMedicalHistory.DiseaseNote = null"
            class="vx-col lg:w-1/7"
            v-model="patientModel.PatientMedicalHistory.IsHaveAsthmatic"
            :vs-value="false"
            vs-name="Areyouasthmatic"
            >{{ $t("no") }}</vs-radio
          >
          <p class="lg:w-1/5 mt-3 mr-4 w-full">{{ $t("Ifyesdescripe") }}:</p>
          <vs-input
            style="width: 19rem"
            :disabled="!patientModel.PatientMedicalHistory.IsHaveAsthmatic"
            v-model="patientModel.PatientMedicalHistory.DiseaseNote"
            :placeholder="$t('DiseaseNote')"
          ></vs-input>
        </div>

        <!-- Anycancerdiagnosishistoryinyourfamily -->
        <div class="vx-row mt-5 w-full">
          <b class="xl:w-3/12 sm:w-1/2 text-left m-2">
            <p>{{ $t("Anycancerdiagnosishistoryinyourfamily") }}</p></b
          >
          <vs-radio
            class="vx-col lg:w-1/7"
            vs-name="ISHaveCancerHistoryinFamily"
            v-model="patientModel.PatientMedicalHistory.ISHaveCancerHistoryinFamily"
            :vs-value="true"
            >{{ $t("yes") }}</vs-radio
          >

          <vs-radio
            @change="patientModel.PatientMedicalHistory.CancerHistoryinFamilyNote = null"
            class="vx-col lg:w-1/7"
            vs-name="ISHaveCancerHistoryinFamily"
            v-model="patientModel.PatientMedicalHistory.ISHaveCancerHistoryinFamily"
            :vs-value="false"
            >{{ $t("no") }}</vs-radio
          >
          <p class="lg:w-1/5 mt-3 w-full">{{ $t("Ifyesdescripe") }}:</p>
          <vs-input
            :disabled="!patientModel.PatientMedicalHistory.ISHaveCancerHistoryinFamily"
            class="vx-col lg:w-1/5 w-full"
            s
            v-model="patientModel.PatientMedicalHistory.CancerHistoryinFamilyNote"
            :placeholder="$t('CancerHistoryinFamilyNote')"
          >
          </vs-input>
        </div>

        <!-- HaveDisease -->
        <div class="vx-row mt-5 w-full">
          <b class="xl:w-3/12 sm:w-1/2 text-left m-2">
            <p>{{ $t("HaveDisease") }}</p></b
          >
          <vs-radio
            class="vx-col lg:w-1/7"
            vs-name="HaveDisease"
            v-model="patientModel.PatientMedicalHistory.IsHaveDisease"
            :vs-value="true"
            >{{ $t("yes") }}</vs-radio
          >

          <vs-radio
            @change="patientModel.PatientMedicalHistory.DiseaseText = null"
            class="vx-col lg:w-1/7"
            vs-name="HaveDisease"
            v-model="patientModel.PatientMedicalHistory.IsHaveDisease"
            :vs-value="false"
            >{{ $t("no") }}</vs-radio
          >
          <p class="vx-col lg:w-1/5 mt-3 w-full">{{ $t("Ifyesdescripe") }}:</p>
          <vs-input
            :disabled="!patientModel.PatientMedicalHistory.IsHaveDisease"
            class="vx-col lg:w-1/5 w-full"
            v-model="patientModel.PatientMedicalHistory.DiseaseText"
            :placeholder="$t('DiseaseText')"
          >
          </vs-input>
        </div>

        <!-- HaveAids -->
        <div class="vx-row mt-5 w-full">
          <b class="xl:w-3/12 sm:w-1/2 text-left m-2">
            <p>{{ $t("HaveAids") }}</p></b
          >
          <vs-radio
            class="vx-col lg:w-1/7"
            vs-name="HaveAids"
            v-model="patientModel.PatientMedicalHistory.ISHaveAids"
            :vs-value="true"
            >{{ $t("yes") }}</vs-radio
          >

          <vs-radio
            @change="patientModel.PatientMedicalHistory.AidsDate = null"
            class="vx-col lg:w-1/7"
            vs-name="HaveAids"
            v-model="patientModel.PatientMedicalHistory.ISHaveAids"
            :vs-value="false"
            >{{ $t("no") }}</vs-radio
          >
          <p class="vx-col lg:w-1/5 mt-3 w-full">{{ $t("Ifyeswhen") }}:</p>
          <datepicker
            :disabled="!patientModel.PatientMedicalHistory.ISHaveAids"
            :placeholder="$t('AidsDate')"
            v-model="patientModel.PatientMedicalHistory.AidsDate"
            class="vx-col lg:w-1/5 w-full"
          ></datepicker>
        </div>

        <!-- HaveHeartProblem -->
        <div class="vx-row mt-5 w-full">
          <b class="xl:w-3/12 sm:w-1/2 text-left m-2">
            <p>{{ $t("HaveHeartProblem") }}</p></b
          >
          <vs-radio
            class="vx-col lg:w-1/7"
            vs-name="HaveHeartProblem"
            v-model="patientModel.PatientMedicalHistory.ISHaveHeartProblem"
            :vs-value="true"
            >{{ $t("yes") }}</vs-radio
          >

          <vs-radio
            class="vx-col lg:w-1/7"
            vs-name="HaveHeartProblem"
            v-model="patientModel.PatientMedicalHistory.ISHaveHeartProblem"
            :vs-value="false"
            >{{ $t("no") }}</vs-radio
          >
        </div>

        <!-- LastBloodAnalysis -->
        <div class="vx-row mt-5">
          <b class="xl:w-3/12 sm:w-1/2 text-left m-2">
            <p>{{ $t("LastBloodAnalysis") }}</p></b
          >

          <datepicker
            :placeholder="$t('LastBloodAnalysis')"
            v-model="patientModel.PatientMedicalHistory.LastBloodAnalysisDate"
            class="vx-col lg:w-1/5 w-full"
          ></datepicker>
        </div>

        <!-- Allergies -->
        <div class="vx-row mt-5">
          <b class="xl:w-3/12 sm:w-1/2 text-left m-2">
            <p>{{ $t("Allergies") }}</p></b
          >

          <vs-input
            class="vx-col lg:w-1/5 w-full"
            v-model="patientModel.PatientMedicalHistory.Allergies"
            :placeholder="$t('AllergiesNote')"
          >
          </vs-input>
        </div>

        <!-- Haveyougonethroughanysurgerybefore -->
        <div class="vx-row">
          <vs-card class="w-full lg:w-2/5 m-5">
            <div class="vx-row mt-5">
              <b class="lg:w-1/2 text-left ml-5 mr-5">
                <p>{{ $t("Haveyougonethroughanysurgerybefore") }}</p></b
              >
              <vs-radio
                class="vx-col lg:w-1/5"
                vs-name="IsSurgeryBefore"
                v-model="patientModel.PatientMedicalHistory.IsSurgeryBefore"
                :vs-value="true"
                >{{ $t("yes") }}</vs-radio
              >

              <vs-radio
                class="vx-col lg:w-1/5"
                vs-name="IsSurgeryBefore"
                v-model="patientModel.PatientMedicalHistory.IsSurgeryBefore"
                :vs-value="false"
                >{{ $t("no") }}</vs-radio
              >
            </div>
            <div class="vx-row m-5">
              <vs-input
                :disabled="!patientModel.PatientMedicalHistory.IsSurgeryBefore"
                class="w-full lg:w-2/3 ml-1 mr-1"
                :label="$t('SurgeryName')"
                v-model="surgeryModel.SurgeryName"
              />
              <vs-button color="wight" :disabled="!surgeryModel.SurgeryName">
                <feather-icon
                  style="margin-left: 10px; color: black"
                  icon="PlusIcon"
                  @click.stop="AddSurgery()"
                  class="cursor-pointer"
                ></feather-icon>
              </vs-button>
            </div>

            <div
              style="text-decoration-line: underline"
              v-if="
                patientModel.PatientMedicalHistory != null &&
                patientModel.PatientMedicalHistory.PatientMedicalHistoryPastSurgeries !=
                  undefined &&
                patientModel.PatientMedicalHistory.PatientMedicalHistoryPastSurgeries
                  .length > 0
              "
            >
              <div
                v-for="(item, index) in patientModel.PatientMedicalHistory
                  .PatientMedicalHistoryPastSurgeries"
                :key="item.ID"
              >
                <div class="vx-row m-5">
                  <h2 class="vx-col lg:w-1/4" style="color: #454a62; font-size: 19px">
                    {{ item.SurgeryName }}
                  </h2>
                  <vs-button color="wight">
                    <feather-icon
                      style="margin-left: 10px; color: black"
                      icon="XIcon"
                      @click.stop="deleteSurgery(index)"
                      class="cursor-pointer"
                    ></feather-icon>
                  </vs-button>
                </div>
              </div>
            </div>
          </vs-card>

          <vs-card class="w-full lg:w-2/5 m-5">
            <b class="lg:w-1/2 text-left ml-5 mr-5">
              <p>{{ $t("Medication") }}</p></b
            >
            <div class="vx-row m-5">
              <vs-input
                class="w-full lg:w-1/4 ml-1 mr-1"
                :label="$t('MedicineName')"
                v-model="medicationModel.MedicineName"
              />
              <vs-input
                class="w-full lg:w-1/4 ml-1 mr-1"
                :label="$t('Doosage')"
                v-model="medicationModel.Dose"
              />
              <vs-input
                type="number"
                class="w-full lg:w-1/4 ml-1 mr-1"
                :label="$t('DurationInDay')"
                v-model="medicationModel.DurationInDay"
              />
              <vs-button
                color="wight"
                class="lg:w-1/5"
                :disabled="
                  !(
                    medicationModel.MedicineName &&
                    medicationModel.Dose &&
                    medicationModel.DurationInDay
                  )
                "
              >
                <feather-icon
                  style="margin-left: 10px; color: black"
                  icon="PlusIcon"
                  @click.stop="AddMedicine()"
                  class="cursor-pointer"
                ></feather-icon>
              </vs-button>
            </div>

            <div
              style="text-decoration-line: underline"
              v-if="
                patientModel.PatientMedicalHistory != null &&
                patientModel.PatientMedicalHistory.PatientMedicalHistoryMedications !=
                  undefined &&
                patientModel.PatientMedicalHistory.PatientMedicalHistoryMedications
                  .length > 0
              "
            >
              <div
                v-for="(item, index) in patientModel.PatientMedicalHistory
                  .PatientMedicalHistoryMedications"
                :key="item.ID"
              >
                <div class="vx-row mt-5">
                  <h2 class="vx-col lg:w-1/4" style="color: #454a62; font-size: 19px">
                    {{ item.MedicineName }}
                  </h2>
                  <h2 class="vx-col lg:w-1/4" style="color: #454a62; font-size: 19px">
                    {{ item.Dose }}
                  </h2>
                  <h2 class="vx-col lg:w-1/4" style="color: #454a62; font-size: 19px">
                    {{ item.DurationInDay }}
                  </h2>
                  <vs-button color="wight">
                    <feather-icon
                      style="margin-left: 10px; color: black"
                      icon="XIcon"
                      @click.stop="deleteMedicine(index)"
                      class="cursor-pointer"
                    ></feather-icon>
                  </vs-button>
                </div>
              </div>
            </div>
          </vs-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moduleGender from "@/store/settings/gender/moduleGender.js";

import Datepicker from "vuejs-datepicker";

export default {
  data() {
    return {
      options: [],
      radios1: "luis",
      date: null,
      surgeryModel: {},
      medicationModel: {},
    };
  },
  props: {
    patientModel: {
      type: Object,
      required: true,
    },
  },
  components: {
    Datepicker,
  },
  computed: {
    genders() {
      return this.$store.state.GenderList.genders;
    },
    companies() {
      return this.$store.state.InsuranceCompanyList.InsuranceCompanies;
    },
  },
  methods: {
    AddMedicine() {
      if (
        this.patientModel.PatientMedicalHistory.PatientMedicalHistoryMedications ==
        undefined
      ) {
        this.patientModel.PatientMedicalHistory.PatientMedicalHistoryMedications = [];
      }
      if (
        this.patientModel.PatientMedicalHistory.PatientMedicalHistoryMedications !=
        undefined
      ) {
        this.patientModel.PatientMedicalHistory.PatientMedicalHistoryMedications.push(
          this.medicationModel
        );
      } else {
        debugger;
        var randListMed = [];
        randListMed.push(this.medicationModel);
        this.patientModel.PatientMedicalHistory.PatientMedicalHistoryMedications = randListMed;
      }

      this.medicationModel = {};
    },
    deleteMedicine(index) {
      this.patientModel.PatientMedicalHistory.PatientMedicalHistoryMedications.splice(
        index,
        1
      );
    },
    AddSurgery() {
      if (
        this.patientModel.PatientMedicalHistory.PatientMedicalHistoryPastSurgeries ==
        undefined
      ) {
        this.patientModel.PatientMedicalHistory.PatientMedicalHistoryPastSurgeries = [];
      }

      if (
        this.patientModel.PatientMedicalHistory.PatientMedicalHistoryPastSurgeries !=
        undefined
      ) {
        debugger;
        this.patientModel.PatientMedicalHistory.PatientMedicalHistoryPastSurgeries.push(
          this.surgeryModel
        );
        debugger;
      } else {
        debugger;
        var randList = [];
        randList.push(this.surgeryModel);
        this.patientModel.PatientMedicalHistory.PatientMedicalHistoryPastSurgeries = randList;
      }
      this.surgeryModel = {};
      debugger;
    },
    deleteSurgery(index) {
      debugger;
      this.patientModel.PatientMedicalHistory.PatientMedicalHistoryPastSurgeries.splice(
        index,
        1
      );
    },
  },
  created() {
    if (!moduleGender.isRegistered) {
      this.$store.registerModule("GenderList", moduleGender);
      moduleGender.isRegistered = true;
    }
    this.$store.dispatch("GenderList/GetAllGenders");
    this.patientModel.PatientMedicalHistory.PatientMedicalHistoryMedications = [];
    this.patientModel.PatientMedicalHistory.PatientMedicalHistoryPastSurgeries = [];
  },
};
</script>
<style>
.textfont {
  font-family: "futuraMedium";
}
</style>
